<template>
	<!-- Deprecated. Might bring back if Troy needs a clock-in / clock-out button -->
	<v-dialog :value="true" min-width="300" max-width="600" persistent noClickAnimation overlay-opacity="0.9" overlay-color="black">
		<v-card>
			<v-card-title>Reconcile Timers</v-card-title>

			<v-card-text>
				<div class="d-flex">
					<v-text-field label="Time Guardian Hours" type="number" v-model="hours" required dense hide-details outlined class="ma-1" min="0"></v-text-field>
					<v-text-field label="Time Guardian Minutes" type="number" v-model="minutes" required dense hide-details outlined class="ma-1" :min="hours > 0 ? -1 : 0"></v-text-field>
				</div>
				<v-text-field label="Recommendation" :value="recommendationStr" required dense hide-details outlined class="mt-3" readonly></v-text-field>
			</v-card-text>

			<v-card-actions>
				<v-btn @click="modal.trigger('close')" class="ml-auto">Close</v-btn>
				<v-btn v-if="recommendation == 'break'" color="primary" @click="addBreak">Add Break</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import moment from "moment";

	export default {
		name: "reconcileTimers",

		props: {
			modal: {
				type: Object,
				required: true,
			},
			timers: {
				type: Array,
				required: true,
			},
		},

		data() {
			let time = moment.duration(
					this.$insight.timers.timerDurationsSum(this.timers),
					"seconds"
				),
				hours = time.hours(),
				minutes = Math.round(time.asMinutes() - hours * 60);

			return {
				origHours: hours,
				origMinutes: minutes,
				hours,
				minutes,

				check: true,
			};
		},

		computed: {
			breakTimers() {
				return this.timers.filter((timer) => {
					return timer.project_id == this.$insight.projects.breakProject;
				});
			},
			currentBreakTime() {
				return this.$insight.timers.timerDurationsSum(this.breakTimers);
			},
			timeDiff() {
				// Seconds
				return (
					(parseInt(this.hours) * 60 + parseInt(this.minutes)) * 60 -
					(parseInt(this.origHours) * 60 + parseInt(this.origMinutes)) *
						60
				);
			},
			recommendation() {
				if (this.timeDiff == 0) {
					return "same";
				} else if (this.timeDiff > 0) {
					// More time in Time Guardian than Chuck It
					// how much break needed?
					let breakTotalNeeded = this.currentBreakTime + this.timeDiff;
					if (breakTotalNeeded > 1200) {
						// Too much break needed
						return "contact";
					} else {
						return "break";
					}
				} else {
					return "too-much";
				}
			},
			recommendationStr() {
				let minutes = Math.abs(this.timeDiff / 60),
					plural = minutes == 1 ? "" : "s";

				switch (this.recommendation) {
					case "same":
						return "The times are exactly the same. No change necessary";
					case "break":
						return `Add ${minutes} minute${plural} of break to user`;
					case "contact":
						return `${minutes} less minute${plural} in Planet than Time Guardian but they used too much break to fix`;
					case "too-much":
						return `${minutes} more minute${plural} in Planet than Time Guardian`;
				}
			},
			date() {
				return moment(this.timers[0].creation_date).endOf("day");
			},
			userId() {
				return this.timers[0].user_id;
			},
		},

		methods: {
			addBreak() {
				let minutesOfBreak = ("0" + this.timeDiff / 60).substr(-2);

				let opts = {
					creation_date: this.date,
					duration: `00:${minutesOfBreak}:00`,
					project_id: this.$insight.projects.breakProject,
					submit: 1,
					active: 0,
					description: `Reconciliation timer created by ${this.$store.getters.userFullName}`,
				};
				opts.creation_date = opts.creation_date.format(
					"YYYY-MM-DD HH:mm:ss"
				);

				this.$xhrRequest
					.send("post", `/api/user/${this.userId}/timer`, opts)
					.then((newTimer) => {
						this.$db.addModels("timer", newTimer, "weekly");
						let day = moment(opts.creation_date).format(
							"dddd, MMMM Do YYYY"
						);
						this.$snotify.success(
							`Reconciled times for ${day}`,
							"Created timer"
						);
						this.modal.trigger("save close");
					})
					.catch(() => {
						this.$snotify.error("Error!", "Create Timer");
					});
			},
		},

		watch: {
			minutes(to) {
				if (to < 0) {
					this.hours--;
					this.minutes = 59;
				} else if (to >= 60) {
					this.hours++;
					this.minutes -= 60;
				}
			},
		},
	};
</script>